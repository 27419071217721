import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
})
export class DashboardLayoutComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() notDashboard: boolean = false;
  @Input() downloader: Downloader | null = null;
  @Input() dialog: Boolean = false;

  ngOnInit(): void {}

  data: string = "";

  constructor(private router: Router) {
    if (router.url.length == 1) {
      this.data = "Dashboard";
    } else {
      this.data = router.url.split("/")[1].replace("-", " ");
      if (this.data.includes("#")) {
        this.data = "";
      }
    }
  }
}

interface Downloader {
  url?: string;
  loading?: boolean;
  notDashboard?: boolean;
}

<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <section class="bg-white border rounded-md p-6">
        <header class="border-b text-lg w-full pb-2 md:text-xl">
            Accommodation Payment Confirmation
        </header>

        <app-notification-alert [data]="notification"></app-notification-alert>

        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>
    </section>
</app-dashboard-layout>
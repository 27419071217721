import { Router } from "@angular/router";
import { saveAuth } from "src/app/util/auth";
import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-admin-login",
  templateUrl: "./admin-login.component.html",
})
export class AdminLoginComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}
  loading = true;
  errorMessage = "";

  ngOnInit(): void {
    const params = new URLSearchParams(location.search);
    const payload = {
      Password: "xxx",
      MatricNo: params.get("matric") || "",
      AuthToken: params.get("token") || "",
      AuthDateTime: params.get("time") || "",
    };

    const httpParams = new HttpParams({
      fromObject: payload,
    });

    const baseUrl = `${environment.BASE_URL}/users/login`;
    this.httpClient
      .post(baseUrl, httpParams)
      .subscribe({
        next: (res: any) => {
          saveAuth({ ...res?.Data, Matric: payload.MatricNo });
          this.router.navigateByUrl("/");
        },
        error: (err: any) => {
          this.loading = false;
          this.errorMessage =
            err?.Message ||
            err?.error?.Message ||
            "An error occurred. Try again";
        },
      })
      .add(() => {
        saveAuth([], "_courses");
        saveAuth({}, "_checks");
        saveAuth(null, "_news");
        saveAuth(null, "_deadlines");
        saveAuth([], "_accommodation");
        saveAuth([], "_slider");
        saveAuth([], "_requirements");
        saveAuth({}, "_passport");
      });
  }
}

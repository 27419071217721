<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <section class="bg-white border rounded-md p-4">
        <header class="border-b flex text-lg w-full pb-6 gap-8 md:text-xl">
            Accommodation Application Status:
        </header>

        <app-notification-alert [data]="notification"></app-notification-alert>

        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <div *ngIf="!skeletonLoader" class="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left">
                <thead class="text-xs text-white uppercase bg-primary/90">
                    <tr>
                        <th scope="col" class="py-3 px-6">
                            SN
                        </th>
                        <th scope="col" class="py-3 px-6">
                            Hall Name
                        </th>
                        <th scope="col" class="py-3 px-6">
                            Reason
                        </th>
                        <th scope="col" class="py-3 px-6">
                            Allocated
                        </th>
                        <th scope="col" class="py-3 px-6">
                            Created on
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let request of payload; let i = index" class="text-gray-700 text-sm">
                        <th scope="row" class="py-4 px-6 font-medium whitespace-nowrap">
                            {{i + 1}}
                        </th>
                        <td class="py-4 px-6">
                            {{request.HallName | titlecase}}
                        </td>
                        <td class="py-4 px-6">
                            {{request.Reason}}
                        </td>
                        <td class="py-4 px-6">
                            <input type="checkbox" [(ngModel)]="request.Acquired">
                        </td>
                        <td class="py-4 px-6">
                            {{request.DateTime | date: "MMM d, y, h:mm a"}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</app-dashboard-layout>
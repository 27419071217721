import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-change-of-programme",
  templateUrl: "./application-change-of-programme.component.html",
  styleUrls: ["./application-change-of-programme.component.scss"],
})
export class ApplicationChangeOfProgrammeComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {};
  complaintAttachment: any = {};
  initialProgramme = "";
  newProgramme = "";
  response: any = {};

  reason = "";

  notification = {};
  downloader: any = null;

  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/users/changeofprogrammeapplication`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.payload = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
        this.skeletonLoader = false;
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });
  }
  submitForm() {
    this.loading = true;
    this.notification = {};

    this.response = {
      NewProgramme: this.newProgramme,
      InitialProgramme: this.initialProgramme,
      ReasonForChange: this.payload.ReasonForChange,
    };
    const baseUrl = `${environment.BASE_URL}/users/savechangeofprogrammeapplication`;
    this.httpClient.post(baseUrl, this.response, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.router.navigateByUrl(
            "/application#Your+change+of+programme+application+has+been+recieved+and+is+awaiting+approval.+Please+see+the+payment+advice+for+details+on+any+fees+required."
          );
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }
}

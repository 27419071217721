import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-student-acceptance-submit",
  templateUrl: "./application-student-acceptance-submit.component.html",
  styleUrls: ["./application-student-acceptance-submit.component.scss"],
})
export class ApplicationStudentAcceptanceSubmitComponent implements OnInit {
  @ViewChild("goUp", { static: true }) contentPage!: ElementRef;
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {};
  response = {};

  company = "";
  state = "";

  notification = {};
  downloader: any = null;

  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const _checks = authData("_checks");
    const { StudentHasFilledBioData, AcceptanceFormAllowed } = _checks;
    if (!StudentHasFilledBioData) {
      this.notification = {
        message: "Please fill in your biodata first",
        error: true,
      };
      return;
    }
    if (!AcceptanceFormAllowed) {
      this.notification = {
        message: "You have already filled your acceptance form",
        error: true,
      };
      return;
    }
    const baseUrl = `${environment.BASE_URL}/biodata/acceptanceform`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.payload = res.Data;
        }
        this.skeletonLoader = false;
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });
  }

  showUp() {
    this.contentPage.nativeElement.scrollIntoView();
  }

  submitForm() {
    this.loading = true;
    this.notification = {};

    const baseUrl = `${environment.BASE_URL}/biodata/saveacceptanceform`;
    this.httpClient.post(baseUrl, "", this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.router.navigateByUrl(
            `/payments#Your+acceptance+form+has+been+successfully+submitted.+You+can+now+generate+your+payment+advice+for+tuition+fee+and+obligatory+fees`
          );
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
    this.showUp();
  }
}

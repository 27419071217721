<app-dashboard-layout [loading]="loading" [downloader]="downloader">
    <app-notification-alert [data]="notification"></app-notification-alert>

    <section *ngIf="refreshing">
        <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
        <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
    </section>


    <section *ngIf="!refreshing" class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-4">
        <app-dashboard-card *ngFor="let link of links" [routerLink]="link.slug" [title]="link.title" [icon]="link.icon"
            [caption]="link.text">
        </app-dashboard-card>
        <app-dashboard-card *ngIf="StudentProfileView" (click)="printProfile()" title="Print Academic Profile"
            icon="/assets/icons/printer.png" caption="Print Academic Profile">
        </app-dashboard-card>
        <app-dashboard-card *ngIf="ScreeningFormView" (click)="printScreeningForm()" title="Print Screening Form"
            icon="/assets/icons/screening80.png" caption="Print Screening Form">
        </app-dashboard-card>
    </section>
</app-dashboard-layout>
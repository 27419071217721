import { environment } from "src/environments/environment";
import { authData } from "src/app/util/auth";
import { Component, EventEmitter, OnInit } from "@angular/core";
import { DataService } from "src/app/http/data.service";

@Component({
  selector: "app-top-nav-bar",
  templateUrl: "./top-nav-bar.component.html",
})
export class TopNavBarComponent implements OnInit {
  constructor(private dataService: DataService) {}
  ngOnInit(): void {}

  open: boolean = false;
  dataEmitter = new EventEmitter<boolean>();

  _auth = authData();
  logo = environment.logo;
  long_name = environment.long_name;
  short_name = environment.short_name;

  sendData(open:boolean) {
    open = !open;
    this.open = open;
    this.dataService.sendData(open);
  }
}

<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <section class="bg-white border rounded-md p-4">
        <header class="Poppins border-b flex text-lg w-full pb-6 gap-8 md:text-xl">
            APPLY FOR DEFERMENT
        </header>

        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <form ngNativeValidate *ngIf="!skeletonLoader" id="referral-application"
            class="max-w-screen-sm grid gap-8 grid-cols-1" (ngSubmit)="submitForm()">
            <app-notification-alert class="pt-2" [data]="notification"></app-notification-alert>

            <h5 class="font-small text-left text-l">
                Please note that upon the approval of this application, your registration for the current semester will
                be deleted.
            </h5>

            <div class="grid gap-8 grid-cols-1">
                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Start Session:
                    </div>
                    <input required type="text" name="startSession" [(ngModel)]="payload.StartSession" [disabled]="true"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Semester:
                    </div>
                    <input required type="text" name="startsemester" [(ngModel)]="payload.StartSemester" [disabled]="true"
                        class="border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light" />
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Resumption Session:
                    </div>
                    <div class="py-8">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select [(ngModel)]="session" class="w-full p-2 focus:outline-none" name="session">
                                <option disabled value="">Select a session</option>
                                <option *ngFor="let session of payload.Sessions">
                                    {{ session }}
                                </option>
                            </select>
                        </div>
                    </div>
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Resumption Semester:
                    </div>
                    <div class="py-8">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select [(ngModel)]="semester" class="w-full p-2 focus:outline-none" name="semester">
                                <option disabled value="">Select a semester</option>
                                <option *ngFor="let semester of payload.Semesters">
                                    {{ semester }}
                                </option>
                            </select>
                        </div>
                    </div>
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Reason:
                    </div>
                    <textarea id="message" rows="4" [(ngModel)]="reason" [required]="true" class="block p-2.5 border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700
                        appearance-none focus:outline-none focus-within:border-primary-light"
                        placeholder="Your reason here...">
                    </textarea>
                </label>
            </div>
            <nav class="flex flex-col py-2 gap-4 justify-center">
                <app-button-primary type="submit" [disabled]="loading" class="mx-auto max-w-screen-sm w-full">
                    Submit
                </app-button-primary>
            </nav>
        </form>
    </section>
</app-dashboard-layout>
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-dashboard-card",
  templateUrl: "./dashboard-card.component.html",
})
export class DashboardCardComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  @Input() icon: string = "";
  @Input() title: string = "";
  @Input() caption: string = "";
  @Input() requirement: boolean = false;
  diff = 0;
  current = 0;
  total = 0;

  _auth = authData();
  _requirementData = authData("_requirements");
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + this._auth.Token,
    }),
  };

  ngOnInit(): void {
    if (this.requirement) {
      this.requirements();
    }
  }

  requirements() {
    if (this._requirementData !== null && this._requirementData.length != 0) {
      this.current = this._requirementData.CurrentCount;
      if (
        this._requirementData.CurrentCount >
        this._requirementData.GraduationCount
      ) {
        this.current = this._requirementData.GraduationCount;
      }
      this.total = this._requirementData.GraduationCount;

      this.diff = this.calculateDeadline(
        this._requirementData.CurrentCount,
        this._requirementData.GraduationCount
      );
    } else {
      const baseUrl = `${environment.BASE_URL}/users/Requirements`;
      this.httpClient.get(baseUrl, this.httpOptions).subscribe((res: any) => {
        if (res.Data !== null) {
          this.current = res.Data.CurrentCount;
          if (res.Data.CurrentCount > res.Data.GraduationCount) {
            this.current = res.Data.GraduationCount;
          }
          this.total = res.Data.GraduationCount;

          this.diff = this.calculateDeadline(
            res.Data.CurrentCount,
            res.Data.GraduationCount
          );
        }
      });
    }
  }

  calculateDeadline(current: number, total: number) {
    const diff = current / total;
    if (current > total) {
      return 1;
    }
    return diff < 0 ? 0 : diff;
  }
}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-student-acceptance",
  templateUrl: "./application-student-acceptance.component.html",
  styleUrls: ["./application-student-acceptance.component.scss"],
})
export class ApplicationStudentAcceptanceComponent implements OnInit {
  constructor(private httpClient: HttpClient) {}

  downloader: any = null;

  payload: any = {};
  notification = {};

  fakeLoader = false;
  loading = false;

  links = [
    {
      title: "Acceptance Form",
      slug: "submit",
      icon: "/assets/icons/application.png",
      text: "Add a line or two here that explains what this card does",
    },
  ];

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const message: string = location.hash.slice(1);
    if (message) {
      this.notification = {
        message: message.replace(/\+/g, " "),
      };
    }
  }

  printAcceptance(): void {
    if (this.loading) return;

    const _checks = authData("_checks");
    const { StudentHasFilledBioData, AcceptanceReportAllowed } = _checks;
    if (!StudentHasFilledBioData) {
      this.notification = {
        message: "You haven't filled your bio-data form",
        error: true,
      };
      return;
    }

    // if (!AcceptanceReportAllowed) {
    //   this.notification = {
    //     message: "You are not allowed to print your acceptance",
    //     error: true,
    //   };
    //   return;
    // }

    this.loading = true;
    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/biodata/printacceptanceform`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.loading = false;
          this.downloader = {
            loading: false,
            name: res.Data.Name,
            url: `data:application/octet-stream;base64,${res.Data.Data}`,
          };
        } else {
          this.downloader = null;
          this.loading = false;
          this.notification = { error: true, message: res.Message };
        }
      },
      error: (err) => {
        this.downloader = null;
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }
}

<app-dashboard-layout>
    <div #goUp></div>
    <app-notification-alert [data]="notification"></app-notification-alert>
    <header class="text-lg w-full pl-4 gap-8 md:text-xl">
        {{hallName}}
        <div class="text-sm text-gray-600 pt-2">
            <div>Please select a room from the available rooms below</div>
        </div>
    </header>

    <form class="flex flex-col mx-auto p-4 z-20 overflow-x-auto justify-center">

        <section *ngIf="refreshing">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <section *ngIf="!refreshing">
            <p class="text-sm text-gray-600">Click
                <button class="text-center text-sm text-primary hover: text-primary-200"
                    (click)="reserveNextAvailableSpace(hallId)">here
                </button> to reserve the next available space.
            </p>

            <div *ngFor="let floor of floors" class="py-2">
                <h2 class="font-medium py-4">
                    {{floor.ordinal}}
                </h2>
                <div class="overflow-x-auto">
                    <div class="  bg-white border-primary border rounded-sm flex p-2 gap-x-2 inline-flex">
                        <div *ngFor="let room of getArray(floor.rooms)" class="flex flex-col text-white gap-y-2">
                            <a [routerLink]="space.AvailableSpaces > 0 ? ['/accommodation/reservation'] : null"
                                [queryParams]="{hall: hallId, room: space.RoomNo, reservation:space.ReservationType, name: hallName}"
                                *ngFor="let space of getArray(room); let j = index"
                                class="text-center text-xs py-4 px-8 w-xs rounded-sm h-full text-white
                                {{space.AvailableSpaces == 0 ? 'bg-error' : space.AvailableSpaces > 0 ? 'bg-success hover:bg-primary' : 'bg-gray-200'}}">
                                <div *ngIf="space.AvailableSpaces >= 0">
                                    <div class="pb-1"> {{ space.RoomNo }} </div>
                                    <div> ({{ space.AvailableSpaces }}) </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <app-button-primary class="w-full text-center" (click)="reserveNextAvailableSpace(hallId)"
                [disabled]="refreshing || loading">
                Reserve the next available space
            </app-button-primary>
        </section>

    </form>
</app-dashboard-layout>
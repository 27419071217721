import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-accommodation-payment-confirmation",
  templateUrl: "./accommodation-payment-confirmation.component.html",
  styleUrls: ["./accommodation-payment-confirmation.component.scss"],
})
export class AccommodationPaymentConfirmationComponent implements OnInit {
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {}

  loading = false;
  refreshing = true;
  notification = {};
  response = {};
  downloader: any = null;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    this.loading = true;
    this.notification = {};

    this.downloader = {
      loading: true,
    };

    const baseUrl = `${environment.BASE_URL}/accomodation/accommodationPaymentConfirmation`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.downloader = {
            loading: false,
            name: res.Data.Name,
            url: `data:application/octet-stream;base64,${res.Data.Data}`,
          };
        } else {
          this.downloader = null;
          this.notification = { error: true, message: res.Message };
          this.loading = false;
          this.refreshing = false;
        }
      },
      error: (err) => {
        this.downloader = null;
        this.notification = errorHandler(err);
        this.loading = false;
        this.refreshing = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }
}

<app-dashboard-layout [loading]="loading" [downloader]="downloader">
  <app-notification-alert [data]="notification"></app-notification-alert>

  <section *ngIf="refreshing">
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
    <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
  </section>

  <section *ngIf="!refreshing">
    <app-no-content *ngIf="!payments.length" caption="No payments to show"> </app-no-content>

    <section *ngIf="payments.length" class="flex flex-col gap-4">
      <a *ngFor="let payment of payments" (click)="onClickPay(payment)"
        class="bg-white rounded-md cursor-pointer border-2 p-4 transform duration-200 block group md:p-6 hover:border-primary hover:bg-primary/5 hover:scale-95">
        <div class="font-medium text-base pb-1 capitalize group-hover:text-primary">
          {{ payment.CollectionTypeName }}
        </div>
        <div class="text-xs pb-6 text-gray-500">{{ payment.Session }}</div>

        <section class="flex gap-8 justify-between items-center">
          <div class="text-sm text-gray-500">₦{{ payment.Amount.toLocaleString() }}</div>
          <a class="font-medium text-primary text-sm">PAY</a>
        </section>
      </a>

      <div class="bg-white rounded-md border-2 p-4 block group md:p-6">
        <div class="font-bold text-base pb-4 capitalize group-hover:text-primary">Total</div>
        <div class="text-sm text-gray-500">₦{{ total.toLocaleString() }}</div>
      </div>

      <!-- <div *ngIf="payments.length" class="flex justify-center">
        <app-button-primary [disabled]="loading" routerLink="/payments/advice"> Generate Advice </app-button-primary> -->
      <!-- <app-button-primary [disabled]="loading" (click)="generateAdvice()">
          Generate Advice
        </app-button-primary> -->
      <!-- </div> -->
    </section>
  </section>
</app-dashboard-layout>

<section role="dialog" aria-modal="true" aria-labelledby="modal-title" class="inset-0 z-10 fixed overflow-y-auto"
  *ngIf="show">
  <div class="flex flex-col h-full text-center p-4 items-center justify-center">
    <div aria-hidden="true" class="bg-gray-500 bg-opacity-75 inset-0 transition-opacity fixed"></div>

    <div
      class="bg-white rounded-lg shadow-xl text-left w-full transform transition-all overflow-hidden sm:max-w-screen-sm sm:my-8">
      <app-loading-indicator *ngIf="loading"></app-loading-indicator>
      <section class="p-4">
        <div>
          <section class="pb-4">
            <div class="text-sm pb-1 text-gray-500">Payment for:</div>
            <div class="font-medium text-lg pb-1 capitalize group-hover:text-primary">
              {{ selectedPayment.CollectionTypeName }}
            </div>
          </section>

          <div class="pb-4 gap-4 justify-between items-center md:flex">
            <section class="">
              <div class="text-sm pb-1 text-gray-500">Session:</div>
              <div class="font-medium text-base pb-1 capitalize group-hover:text-primary">
                {{ selectedPayment.Session }}
              </div>
            </section>

            <section class="md:text-right">
              <div class="text-sm pb-1 text-gray-500">Amount to pay:</div>
              <select name="paymentType" [(ngModel)]="paymentType" (ngModelChange)="refreshRRR()"
                [disabled]="!selectedPayment.AllowPartPayment"
                class="border rounded border-primary/50 text-sm w-full p-2 hover:outline-none">
                <option disabled value="">Select Amount</option>
                <option value="General" selected>₦{{ selectedPayment.Amount.toLocaleString() }} (Full payment)</option>
                <ng-template [ngIf]="selectedPayment.AllowPartPayment">
                  <option *ngIf="selectedPayment.MinimumFeeForRegistration" value="Minimum Fee For Registration">
                    ₦{{ selectedPayment.MinimumFeeForRegistration.toLocaleString() }}
                    (Minimum For Registration)
                  </option>
                  <option *ngIf="selectedPayment.MinimumFeeForDocket" value="Minimum Fee For Docket">
                    ₦{{ selectedPayment.MinimumFeeForDocket.toLocaleString() }}
                    (Minimum For Docket)
                  </option>
                </ng-template>
              </select>
            </section>
          </div>
        </div>

        <div class="text-right">
          <button class="bg-white text-sm text-center uppercase whitespace-nowrap hover:text-primary"
            (click)="generateAdvice()">Click Here To Generate
            {{paymentType}} Payment Advice
          </button>
        </div>

        <nav class="grid pt-4 gap-4 grid-cols-2">
          <button (click)="toggleDialog()"
            class="bg-white border border-error rounded font-medium text-error text-sm text-center py-2 px-4 block uppercase whitespace-nowrap md:px-8 hover:bg-error hover:text-black">
            Close
          </button>

          <app-button-primary class="w-full" [disabled]="!rrr">
            <a *ngIf="rrr" disa target="_blank" (click)="toggleDialog()" [href]="'https://pay.tranzgate.com.ng/PayRemitaOnline.aspx?rrr=' +
              rrr">
              Pay now </a>
            <a *ngIf="!rrr" disa target="_blank" (click)="toggleDialog()">
              Pay now </a>
          </app-button-primary>
        </nav>
      </section>
    </div>
  </div>
</section>
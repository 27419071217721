import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-accommodation-reservation",
  templateUrl: "./accommodation-reservation.component.html",
  styleUrls: ["./accommodation-reservation.component.scss"],
})
export class AccommodationReservationComponent implements OnInit {
  @ViewChild("goUp", { static: true }) contentPage!: ElementRef;
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {}

  hallId: any;
  reservation: any;
  room: any;
  hallName: any;
  num = 0;
  input = "input";

  accommodationreservation: any;

  loading = false;
  refreshing = true;
  notification = {};
  response = {};
  downloader: any = null;
  skeletonLoader = true;
  accommodation: any;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    this.accommodation = authData("_accommodation");
    this.hallId = this.activatedRoute.snapshot.queryParamMap.get("hall");
    this.room = this.activatedRoute.snapshot.queryParamMap.get("room");
    this.hallName = this.activatedRoute.snapshot.queryParamMap.get("name");
    this.reservation =
      this.activatedRoute.snapshot.queryParamMap.get("reservation");

    if (
      this.hallId == null ||
      this.room == null ||
      this.reservation == null ||
      this.hallName == null
    ) {
      this.router.navigateByUrl(
        `/accommodation#Could+not+make+reservation+for+your+selected+input.`
      );
      return;
    }

    this.refreshing = true;

    const baseUrl = `${environment.BASE_URL}/accomodation/accommodationreservation?hallId=${this.hallId}&room=${this.room}&reservationType=${this.reservation}`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.accommodationreservation = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
      },
      error: (err: any) => {
        this.notification = errorHandler(err);
      },
      complete: () => {
        this.skeletonLoader = false;
        this.refreshing = false;
      },
    });
  }
  reserveSpace(num: number) {
    this.loading = true;
    this.response = {
      HallId: this.hallId,
      Room: this.room,
      ReservationType: this.reservation,
      NumberofSpaces: this.num,
    };
    const baseUrl = `${environment.BASE_URL}/accomodation/saveAccommodationReservation`;
    this.httpClient.post(baseUrl, this.response, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          if (this.accommodation.QueueAccommodation) {
            this.router.navigateByUrl("/accommodation#submittedtoqueue=1");
          } else {
            this.router.navigateByUrl("/accommodation#submittedtoqueue=0");
          }
        } else {
          this.showUp();
          this.notification = errorHandler(res);
        }
        this.loading = false;
      },
      error: (err) => {
        this.showUp();
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }
  showUp() {
    this.contentPage.nativeElement.scrollIntoView();
  }
}

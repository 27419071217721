import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { authData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-application-bank-information",
  templateUrl: "./application-bank-information.component.html",
  styleUrls: ["./application-bank-information.component.scss"],
})
export class ApplicationBankInformationComponent implements OnInit {
  constructor(private httpClient: HttpClient, private router: Router) {}

  payload: any = {};
  result: any = {};

  bank = "";

  response = {};

  notification = {};
  downloader: any = null;

  loading = false;
  skeletonLoader = true;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  ngOnInit(): void {
    const baseUrl = `${environment.BASE_URL}/users/bankaccountinfo`;
    const url = `${environment.BASE_URL}/users/banks`;
    this.httpClient.get(url, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.result = res.Data;
        } else {
          this.notification = errorHandler(res);
        }
      },
      error: (err) => {
        this.skeletonLoader = false;
        this.notification = errorHandler(err);
      },
    });
    if (this.skeletonLoader) {
      this.httpClient.get(baseUrl, this.httpOptions).subscribe({
        next: (res: any) => {
          if (res.Success) {
            this.payload = res.Data;
          }
          this.skeletonLoader = false;
        },
        error: (err) => {
          this.skeletonLoader = false;
          this.notification = errorHandler(err);
        },
      });
    }
  }
  submitForm() {
    this.loading = true;
    this.notification = {};

    this.response = {
      BankID: this.bank,
      AccountName: this.payload.AccountName,
      AccountNumber: this.payload.AccountNumber,
      SortCode: this.payload.SortCode,
    };

    const baseUrl = `${environment.BASE_URL}/users/bankaccountinfo`;
    this.httpClient.post(baseUrl, this.response, this.httpOptions).subscribe({
      next: (res: any) => {
        if (res.Success) {
          this.router.navigateByUrl(
            "/application#Your+bank+account+information+has+been+saved+successfully."
          );
        } else {
          this.notification = errorHandler(res);
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this.notification = errorHandler(err);
      },
    });
  }
}

<div
  class="bg-white rounded-md cursor-pointer h-full border-2 text-left p-6 transform duration-200 group hover:border-primary hover:scale-105 pb-2">
  <ng-content></ng-content>

  <img [src]="icon" class="rounded-full h-16 w-16" alt="" />

  <div class="font-medium text-sm pt-2 pb-1 capitalize md:text-lg">
    {{ title }}
  </div>

  <div class="text-xs text-gray-500">
    <span *ngIf="requirement"> &nbsp; {{current}}/{{total}} Completed</span>
  </div>

  <div *ngIf="requirement" class="rounded-md bg-primary/20 h-1.5 mt-3 pb-0">
    <div class="bg-gradient-to-r from-primary rounded-md h-full to-primary/70" [style]="'width: ' + diff * 100 + '%'">
    </div>
  </div>
</div>
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "src/app/http/data.service";
import { authData, removeAuth, saveAuth } from "src/app/util/auth";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-account-dropdown",
  templateUrl: "./account-dropdown.component.html",
})
export class AccountDropdownComponent implements OnInit {
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private dataService: DataService
  ) {}

  _auth = authData();
  _passport = authData("_passport");
  links = [
    {
      title: "Change Password",
      slug: "/reset-password",
      icon: "password",
    },
    { title: "News & Updates", slug: "/news", icon: "newspaper" },
  ];

  loading = false;
  notification = {};
  PassportData = "";

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  async ngOnInit(): Promise<void> {
    this.PassportData = await this.dataService.getPassportData();
  }

  logoutUser(): void {
    removeAuth();
    this.dataService.clearData();
    this.router.navigateByUrl("/login");
  }
}

<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <div #goUp></div>
    <section class="bg-white border rounded-md p-6">
        <header class="border-b text-lg w-full pb-2 md:text-xl">
            Accommodation Reservation in <b>{{hallName | titlecase}}</b>
        </header>
        <app-notification-alert [data]="notification"></app-notification-alert>
        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <div *ngIf="!skeletonLoader" class="pt-4">
            <div class="text-sm text-gray-600">
                <label>Are you sure you want to reserve this space? Once you do, you will not be able to change it or
                    cancel until the reservation expires in {{accommodationreservation.ExpiryDays}} days(s)</label>
                <label *ngIf="!accommodationreservation.IncludeWeekendsInReservationExpiryPeriod"> (Excluding
                    Weekends)</label>
            </div>
            <div class="inline-flex pt-4">
                <div class="font-medium text-grey-darker text-sm mb-2 mr-2 flex items-center">
                    Please select the number of spaces you wish to reserve in this room:
                </div>
                <div class="bg-white border border-primary rounded-lg p-2">
                    <select [(ngModel)]="num" class="focus:outline-none p-2" name="num">
                        <option selected disabled>0</option>
                        <option *ngFor="let num of accommodationreservation.NumberOfSpaces">
                            {{ num }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="text-sm text-gray-600 pt-4">
                <label>See total accommodation costs below:</label>
                <div *ngFor="let item of accommodationreservation.AccommodationCosts | keyvalue">
                    <label>{{ item.key }} space(s):
                        ₦ {{ item.value }}</label>
                </div>
            </div>
            <div class="text-lg text-gray-600 pt-4">
                <label><b>Please see terms and conditions as well as instructions below</b></label>
            </div>
            <div [innerHtml]="accommodationreservation.ReservationInstructions" class="text-sm
            text-gray-600 pt-4">
            </div>
            <div [innerHtml]="accommodationreservation.AccommodationInstructionsHTML" class="text-sm
            text-gray-600 pt-4">
            </div>
            <div class="text-sm text-primary pt-4">
                <label><b>By making this reservation, you agree to all terms and conditions stipulated by
                        {{accommodationreservation.InstitutionName}} which may be subject to change without prior
                        notice.</b>
                </label>
            </div>
            <nav class="flex flex-col py-2 gap-4 justify-center">
                <app-button-primary [type]="input" (click)="reserveSpace(num)" [disabled]="num == 0 || loading"
                    class="mx-auto max-w-screen-sm w-full">
                    Reserve Space(s)
                </app-button-primary>
            </nav>
        </div>
    </section>
</app-dashboard-layout>
<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <section class="bg-white border rounded-md p-4">
        <header class="border-b flex text-lg w-full pb-6 gap-8 md:text-xl">
            Apply for change of programme:
        </header>

        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <form ngNativeValidate *ngIf="!skeletonLoader" id="referral-application"
            class="max-w-screen-sm grid gap-8 grid-cols-1 " (ngSubmit)="submitForm()">
            <app-notification-alert class="pt-4" [data]="notification"></app-notification-alert>

            <div class="grid gap-8 grid-cols-1">

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Select Programme:
                    </div>
                    <div class="py-8">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select [(ngModel)]="newProgramme" class="w-full p-2 focus:outline-none" name="complaint">
                                <option disabled value="">Please select the programme you wish to transfer
                                    to</option>
                                <option *ngFor="let programme of payload.ProgrammesDTO"
                                    [selected]="payload.NewProgramme == programme.ProgrammeID"
                                    value="{{programme.ProgrammeID}}">
                                    {{ programme.ProgrammeName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Effective Session:
                    </div>
                    <label
                        class="rounded text-sm w-full py-2 px-3 text-gray-700 appearance-none focus:outline-none focus-within:border-primary-light">
                        {{ payload.ChangeOfProgrammeApplicationSession }}</label>
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Reason for Change:
                    </div>
                    <textarea id="message" rows="4" [(ngModel)]="payload.ReasonForChange" [required]="true"
                        class="block p-2.5 border border-primary-lighter rounded text-sm w-full py-2 px-3 text-gray-700
                        appearance-none focus:outline-none focus-within:border-primary-light"
                        placeholder="Your reason here...">
                    </textarea>
                </label>

                <label class="block">
                    <div class="font-medium text-grey-darker text-sm mb-2 block">
                        Have you changed your Programme before? If yes, please select your initial programme
                    </div>
                    <div class="py-8">
                        <div class="bg-white border border-primary rounded-lg w-full p-2">
                            <select [(ngModel)]="initialProgramme" class="w-full p-2 focus:outline-none"
                                name="complaint">
                                <option disabled value="">Please select your intial programme if you have transferred
                                    before</option>
                                <option *ngFor="let programme of payload.InitialProgrammesDTO"
                                    value="{{programme.ProgrammeID}}"
                                    [selected]="payload.InitialProgramme == programme.ProgrammeID">
                                    {{ programme.ProgrammeName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </label>
            </div>
            <nav class=" flex flex-col py-2 gap-4 justify-center">
                <app-button-primary type="submit" [disabled]="loading" class="mx-auto max-w-screen-sm w-full">
                    Apply
                </app-button-primary>
            </nav>
        </form>
    </section>
</app-dashboard-layout>
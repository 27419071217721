<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <div #goUp></div>
    <section class="bg-white border rounded-md p-4">
        <header class="border-b flex text-lg w-full pb-4 gap-8 md:text-xl">
            Acceptance Form
        </header>
        <app-notification-alert class="pt-4" [data]="notification"></app-notification-alert>
        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <form ngNativeValidate *ngIf="!skeletonLoader" id="referral-application"
            class="max-w-screen-sm grid gap-8 grid-cols-1 " (ngSubmit)="submitForm()">
            <div class="grid gap-8 grid-cols-1">
                <div [innerHtml]="payload" class="block pt-4"></div>
                <label class="block">
                    <input required type="checkbox" name="confirmation"/>
                    Click Here to Accept
                </label>
            </div>
            <nav class="flex flex-col py-2 gap-4 justify-center">
                <app-button-primary type="submit" [disabled]="loading" class="mx-auto max-w-screen-sm w-full">
                    Submit
                </app-button-primary>
            </nav>
        </form>
    </section>
</app-dashboard-layout>
<header
  class="bg-white border-b flex py-2 px-4 items-center justify-between md:px-8"
>
<div class="block pb-2 md:hidden">
  <nav [class.block]="!open">
    <button class="text-gray-500 w-10 h-10 relative focus:outline-none" (click)="sendData(open)">
      <span class="sr-only">Open main menu</span>
      <div class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <span aria-hidden="true"
          class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
          [ngClass]="{'rotate-45': open,'-translate-y-1.5': !open}"></span>
        <span aria-hidden="true"
          class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
          [ngClass]="{'opacity-0': open}"></span>
        <span aria-hidden="true"
          class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
          [ngClass]="{'-rotate-45': open, 'translate-y-1.5': !open}"></span>
      </div>
    </button>
  </nav>
</div>

  <h1 class="flex-shrink-0 leading-none">
    <a routerLink="/" class="flex items-center">
      <img [src]="logo" width="37" height="37" alt="logo" />
      <div class="font-semibold text-primary ml-2 uppercase text-lg">
        <div class="hidden md:block">{{ long_name }}</div>
        <div class="md:hidden">{{ short_name }}</div>
      </div>
    </a>
  </h1>

  <div class="flex flex-shrink-0 gap-4 items-center">
    <app-account-dropdown></app-account-dropdown>
  </div>
</header>

<app-dashboard-layout [notDashboard]="true" [downloader]="downloader" [loading]="loading || skeletonLoader">
    <app-notification-alert [data]="notification"></app-notification-alert>
    <section class="bg-white border rounded-md p-6">
        <header class="border-b text-lg w-full pb-2 md:text-xl">
            Accommodation Reservation Confirmation
        </header>
        <section *ngIf="skeletonLoader">
            <div class="border rounded-md bg-gray-300 h-16 my-8 animate-pulse"></div>
            <div class="border rounded-md mx-auto max-w-md bg-gray-300 h-8 my-8 animate-pulse"></div>
        </section>

        <div *ngIf="!skeletonLoader" class="pt-4">
            <div *ngIf="!accommodation.ReservationFullyPaid">
                <div class="text-sm text-gray-600">
                    <label>You have been assigned <b>{{accommodationreservation.Spaces}}</b> space(s) in:</label><br />
                    <label><b>{{accommodationreservation.HallName | titlecase}}</b> Room
                        <b>{{accommodationreservation.Room}}</b></label>
                </div>
                <div class="text-sm text-gray-600 pt-4">
                    <label>This reservation will be held for {{accommodationreservation.ExpiryDays}} day(s)</label>
                    <label *ngIf="!accommodationreservation.IncludeWeekendsInReservationExpiryPeriod"> (Excluding
                        Weekends) (until
                        <b>{{accommodationreservation.ExpiryDateTime | date: "EEEE, MMMM d, y, h:mm a"}}</b>)</label>
                </div>
                <div class="text-sm text-gray-600 pt-4">
                    <p>If your payment is not received before then, this reservation will automatically expire and the
                        space(s) you have reserved may be taken by someone else.</p>
                    <br />
                    <p>Please view your <a class="text-center text-sm text-primary hover: text-primary-200"
                            [routerLink]="['/payments/advice']">payment advice
                        </a> for details on the accommodation fees.</p>
                    <br />
                    <p>When full payment is made, you can download your confirmation <button
                            class="text-center text-sm text-primary hover: text-primary-200"
                            (click)="accommodationPaymentConfirmation()">here.</button></p>
                </div>
            </div>
            <div *ngIf="accommodation.ReservationFullyPaid">
                <p>Download your confirmation
                    <button class="text-center text-sm text-primary hover: text-primary-200"
                        (click)="accommodationPaymentConfirmation()">here.</button>
                </p>
            </div>
            <div class="text-lg text-gray-600 pt-4">
                <label><b>Please see terms and conditions as well as further instructions below</b></label>
            </div>
            <div [innerHtml]="accommodationreservation.HallInstructions" class="text-sm
            text-gray-600 pt-4">
            </div>
            <div [innerHtml]="accommodationreservation.ProgrammeTypeAccomodationInstructions" class="text-sm
            text-gray-600 pt-4">
            </div>
        </div>
    </section>
</app-dashboard-layout>
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { authData, saveAuth } from "../util/auth";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private dataSubject = new Subject<boolean>();
  PassportData = "";
  AccommodationData = "";
  private dataLoaded = "";
  private dataSliderLoaded = "";
  private checkDataSubject = new BehaviorSubject<any>(null);
  private sliderDataSubject = new BehaviorSubject<any>(null);
  accommodationInstructions: string | null = null;


  private REST_API_SERVER =
    "httpfs://reqres.in/api/users?page=2" || "http://localhost:44342";

  constructor(private httpClient: HttpClient) { }

  public sendGetRequest(url: string) {
    return this.httpClient.get(this.REST_API_SERVER + url);
  }
  sendData(data: boolean) {
    this.dataSubject.next(data);
  }

  getData() {
    return this.dataSubject.asObservable();
  }
  async getPassportData(): Promise<string> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData().Token,
      }),
    };
    if (!this.PassportData) {
      const baseUrl = `${environment.BASE_URL}/users/passport`;
      const res: any = await this.httpClient
        .get(baseUrl, httpOptions)
        .toPromise();
      if (res.Success) {
        this.PassportData = res.Data.PassportData;
      }
      return this.PassportData;
    } else {
      return this.PassportData;
    }
  }
  clearData() {
    this.PassportData = "";
    this.AccommodationData = "";
    this.dataLoaded = "";
    this.dataSliderLoaded = "";
  }
  async fetchChecks(): Promise<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData().Token,
      }),
    };
    if (!this.dataLoaded) {
      const baseUrl = `${environment.BASE_URL}/users/studentChecks`;
      const res: any = await this.httpClient
        .get(baseUrl, httpOptions)
        .toPromise();
      if (res.Success) {
        this.dataLoaded = res.Data;
      }
      return this.dataLoaded;
    } else {
      return this.dataLoaded;
    }
  }
  async fetchSlider(): Promise<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData().Token,
      }),
    };
    if (!this.dataSliderLoaded) {
      const baseUrl = `${environment.BASE_URL}/users/sliderContentManager`;
      const res: any = await this.httpClient
        .get(baseUrl, httpOptions)
        .toPromise();
      if (res.Success) {
        this.dataSliderLoaded = res.Data;
      }
      return this.dataSliderLoaded;
    } else {
      return this.dataSliderLoaded;
    }
  }
  async accommodationChecks(): Promise<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData().Token,
      }),
    };
    if (!this.AccommodationData) {
      const baseUrl = `${environment.BASE_URL}/accomodation/accommodationChecks`;
      const res: any = await this.httpClient
        .get(baseUrl, httpOptions)
        .toPromise();
      if (res.Success) {
        this.AccommodationData = res.Data;
      }
      return this.AccommodationData;
    } else {
      return this.AccommodationData;
    }
  }

  async getAccommodationInstructions(): Promise<string | null> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + authData().Token,
      }),
    };
    if (!this.accommodationInstructions) {
      const baseUrl = `${environment.BASE_URL}/accomodation/accommodationInstructions`;
      const res: any = await this.httpClient
        .get(baseUrl, httpOptions)
        .toPromise();
      if (res.Success) {
        this.accommodationInstructions = res.Data;
      }
    }
    return this.accommodationInstructions;
  }
}

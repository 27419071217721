<section role="dialog" aria-modal="true" aria-labelledby="modal-title" class="inset-0 z-10 fixed overflow-y-auto">
    <div class="flex flex-col h-full text-center p-4 items-center justify-center">
        <div aria-hidden="true" class="bg-gray-500 bg-opacity-75 inset-0 transition-opacity fixed"></div>

        <div
            class="bg-white rounded-lg shadow-xl text-left w-full transform transition-all overflow-hidden sm:max-w-screen-sm sm:my-8">
            <section class="p-4">
                <div class="text-center p-4">
                    <div
                        class="text-primary animate-spin text-4xl duration-[5000s] material-icons">
                        hourglass_top
                    </div>

                    <div class="pt-4">
                        Please wait, your request is being processed
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>
import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-general-dialog",
  templateUrl: "./general-dialog.component.html",
  styleUrls: ["./general-dialog.component.scss"],
})
export class GeneralDialogComponent implements OnInit {
  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}

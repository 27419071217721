import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "src/app/http/data.service";
import { authData, promiseAuthData } from "src/app/util/auth";
import { errorHandler } from "src/app/util/errorHandler";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-dashboard-index",
  templateUrl: "./dashboard-index.component.html",
})
export class DashboardIndexComponent implements OnInit {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dataService: DataService
  ) {}

  links: Link[] = [];
  payload: any = [];
  notification = {};
  _auth = authData();
  _sliderData = authData("_slider");
  _checks = authData("_checks");
  showPopup = false;
  dialog = false;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: "Bearer " + authData().Token,
    }),
  };

  sliderPromise = this.dataService.fetchSlider();
  checkPromise = this.dataService.fetchChecks();

  async ngOnInit(): Promise<void> {
    const message: string = location.hash.slice(1);
    if (message)
      this.notification = {
        error: true,
        message: decodeURIComponent(message),
      };

    this.links.push({
      title: "Payments",
      slug: "/payments",
      icon: "/assets/icons/credit-card.png",
      text: "Add a line or two here that explains what this card does",
    });

    this.links.push({
      title: "Applications",
      slug: "/application",
      icon: "/assets/icons/homework.png",
      text: "Add a line or two here that explains what this card does",
    });

    // this.links.push({
    //   title: "Student Data",
    //   slug: "/bio-data",
    //   icon: "/assets/icons/visa.png",
    //   text: "Add a line or two here that explains what this card does",
    // });
    this.checkPromise
      .then((result) => {
        if (result.Success) {
          if (result.Data.ShowAcceptance) {
            this.links.push({
              title: "Acceptance",
              slug: "/application/acceptance",
              icon: "/assets/icons/view100.png",
              text: "Add a line or two here that explains what this card does",
            });
          }
        }
      })
      .catch((error) => {});
    // this.links.push({
    //   title: "Student Data",
    //   slug: "/bio-data",
    //   icon: "/assets/icons/visa.png",
    //   text: "Add a line or two here that explains what this card does",
    // });

    this.sliderPromise
      .then((result) => {
        this.payload = result.Data;
      })
      .catch((error) => {});

    if (this._auth.ShowBiodataPopup) this.showPopup = true;
  }

  onOkClicked(bioerr: any) {
    this.showPopup = false;

    if (bioerr) {
      this.notification = {
        error: true,
        message: bioerr,
      };
    }
  }
  refreshPayment(): void {
    this.notification = {};
    this.dialog = true;

    const baseUrl = `${environment.BASE_URL}/payments/refereshStatus`;
    this.httpClient.get(baseUrl, this.httpOptions).subscribe({
      next: (res: any) => {
        this.dialog = false;
        this.payload = res.Data;
        this.notification = {
          error: false,
          message: "Payment refreshed successfully",
        };
      },
      error: (err) => {
        this.dialog = false;
        this.notification = errorHandler(err);
      },
    });
  }
}

interface Link {
  title: string;
  slug: string;
  icon: string;
  text: string;
}
